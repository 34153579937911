import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types"
import React,{ useState, useEffect } from "react"
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Image from "./image"
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import HomeIcon from '@material-ui/icons/Home';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    zIndex: 5,
    top: 0, 
  },
  paper: {   
    backgroundColor: "rgb(0,0,0,0)",
    color: "white"
  },
  paper2: {   
    backgroundColor: "white"
  },
}));


const HeaderXs = ({mode}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar className={mode === "dark" ? classes.paper: classes.paper2}>
    <div style={{width: "20%"}}>
      <Link
        to="/"
        aria-label= "topへ"
        style={{
          textDecoration: `none`,
          color: 'gray',
        }}
      >
        <div style={{flexGrow: 1, minWidth: "180px",maxWidth: "300px"}}>
          {
            mode==="dark" ? <Image filename="inasaku_logo_dark.png" /> : <Image filename="inasaku_logo.png" />
          }
        </div>
      </Link>
    </div>
    <div style={{flexGrow: 1,textAlign: "right"}}>
      <MenuIcon style={{color: mode==="dark" ? "white": ""}}　onClick={handleClick}/>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to="/"
        >
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Top"/>
        </MenuItem>
        <MenuItem
          component={Link}
          to="/#features"
        >
          <ListItemIcon>
            <EmojiObjectsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="特徴"/>
        </MenuItem> 
        <MenuItem
          component={Link}
          to="/overview"
        >
          <ListItemIcon>
            <FavoriteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="概要"/>
        </MenuItem> 
        <MenuItem
          component={Link}
          to="/contact"
          style={{backgroundColor: '#3f51b5', color: 'white'}}
        >
          <ListItemIcon>
            <ContactSupportIcon fontSize="small" style= {{color: 'white'}} />
          </ListItemIcon>
          <ListItemText primary="お問合せ"/>
        </MenuItem>
        <MenuItem
          component={Link}
          to="https://www.inasaku.net"
          style= {{backgroundColor: '#f50057', color: 'white'}}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" style= {{color: 'white'}}/>
          </ListItemIcon>
          <ListItemText primary="ログイン"/>
        </MenuItem>
      </Menu>

    </div>
  </Toolbar>
  )

};

const HeaderMd = ({ siteTitle,pathname,mode }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Toolbar className={mode === "dark" ? classes.paper: classes.paper2}>
      <div style={{width: "25%"}}>
        <Link
          to="/"
          aria-label= "topへ"
          style={{
            textDecoration: `none`,
            color: 'gray',
          }}
        >
          <div style={{flexGrow: 1, minWidth: "220px",maxWidth: "300px"}}>
            {
              mode==="dark" ? <Image filename="inasaku_logo_dark.png" /> : <Image filename="inasaku_logo.png" />
            }
          </div>
        </Link>
      </div>

      {pathname ==="/" &&
        <>
        <div style={{flexGrow: 1}}>
          <Tabs
            style={{float: "right"}}
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{style: {background: mode==="dark" ? 'white':""}}}
            indicatorColor="primary"
            centered
          >
            <Tab style={{fontSize: 16, fontWeight: "bold"}} label="TOP" key="TOP" to="/" component={Link} ></Tab>
            <Tab style={{fontSize: 16, fontWeight: "bold"}} label="特徴" key="特徴" to="/#features" component={Link} />
            <Link to="/contact/" style={{paddingLeft: 10,textDecoration: "none", paddingRight: 5}} >
            {
              mode==="dark" ?
                <Button style={{fontSize: 16}} variant="contained" color="primary" endIcon={<ContactSupportIcon />}>お問合せ</Button>
              :
                <Button style={{fontSize: 16}} variant="outlined" color="primary" endIcon={<ContactSupportIcon />}>お問合せ</Button>
            }
            </Link>
            <a href= {`https://www.inasaku.net`} target="_blank" rel="noreferrer"  aria-label="稲作支援SaaSページへ"　style = {{paddingLeft: 5,textDecoration: "none", paddingRight: 10}}>
            {
              mode==="dark" ?
                <Button style={{fontSize: 16}} variant="contained" color="secondary" endIcon={<PersonIcon />}>ログイン</Button>
              :
                <Button style={{fontSize: 16}} variant="outlined" color="secondary" endIcon={<PersonIcon />}>ログイン</Button>
            }
            </a>
          </Tabs>
        </div>
        </>
      }

      {pathname!=="/" &&
        <div style={{flexGrow: 1,textAlign: "right"}}>
          <Link
            style={{textDecoration: "none"}}
            to="/"> 
            <p style={{fontSize: "20px", color: mode==="dark" ? "white": ""}}>
              <strong>TOPへ</strong>
            </p>
          </Link>
        </div>
      }
      
      
    </Toolbar>
  )
};


const Header = ({ siteTitle,pathname,breakpoints }) => {
  const classes = useStyles();
  const [mode, setMode] = useState("dark");
  const listenScrollEvent = event => {
    if (window.scrollY < 5) {
      return setMode("dark");
    } else if (window.scrollY > 35) {
      return setMode("light");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <header className={classes.header}>
        { 
          breakpoints?.md ? <HeaderXs  mode={mode}/> : null
        }
        { 
          breakpoints?.l ? <HeaderMd siteTitle={siteTitle} mode={mode} pathname={pathname} /> : null  
        }
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
