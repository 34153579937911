
import React from "react"
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  //faTwitter,
  faFacebookSquare,
  //faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import {makeStyles} from "@material-ui/core";
import Image from "./image"

// スタイルの記述をする
const useStyles = makeStyles((theme) => ({
  outer: {
    width: "100%",
    height: "100%",
    paddingBottom: theme.spacing(4),
    //backgroundColor: theme.palette.background.default
  },

  container: {
    width: "100%",
    minHeight: "200px",
    display: "flex",
    alignItems: "center",
  
  },

  baseStyles: {
    width: '100%',
    backgroundColor: 'dimgray',
    margin: 0,
    paddingTop: 20,
    position: "absolute",
    bottom: 0,
  },
  site: {
    flexGrow: 1,
    color: "white",
  },
  logo: {
    maxWidth: 150,
    paddingBottom: 20,
  },

  sns: {
    color: "white",
    textDecoration: "none",
    margin: 15,
  },

  copyright: {
    color: "white",
    textAlign: "center"
  },
  phrase: {
    marginTop: 150,
  }

}));

export default function Footer(props) {

  const classes = useStyles();
  const breakpoints = props.breakpoints

  return (
    
    <footer>
      <div className={classes.baseStyles}>
        <Container component="div" m={5} className={classes.container}>
          <div className={classes.site}  style = {{fontSize: breakpoints?.md ? 14 : 18}}>
            <div style = { {paddingBottom: 20} } >
              <a href= {`https://www.stansystem.co.jp`} target="_blank" rel="noreferrer"  aria-label="スタンシステム"　style = {{textDecoration: "none", color: "white",fontSize: breakpoints?.md ? 18 : 22}}>
              <div style = {{paddingBottom: 5, maxWidth: breakpoints?.md ? 120 : 180}}><Image filename="logo3.png" /></div>
              スタンシステム株式会社
              </a>
            </div>
            <div style = {{paddingBottom: 10}} >
              〒770-0941<br/>
              　徳島県徳島市万代町3丁目5-4 近藤ビル
            </div>            
          </div>
          <div>
{/*
            <a href="https://twitter.com/"　className={classes.sns}>
              <FontAwesomeIcon icon={faTwitter} />
              <span className="sr-only">Twitter</span>
            </a>
*/}          
            <a href="https://ja-jp.facebook.com/stansystem"　className={classes.sns}  aria-label="スタンシステムFBページへ"　style = {{fontSize: breakpoints?.md ? 24 : 28} } target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebookSquare} />
              <span className="sr-only">Facebook</span>
            </a>
{/*          
            <a href="http://instagram.com/"　className={classes.sns}>
              <FontAwesomeIcon icon={faInstagram} />
              <span className="sr-only">Instagram</span>
            </a>  
*/}
          </div>
        </Container>
        <div className={classes.copyright} style = {{fontSize: breakpoints?.md ? 10 : 16} } >Copyright(C) Stan Systems Corporation All Rights Reserved.</div>
      </div>
      
    </footer>
  );
}