/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React, {useEffect,useState} from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import {makeStyles} from "@material-ui/core";
 import Header from "./header"
 import Footer from "./footer"
 import "./layout.css"
 
 const useStyles = makeStyles((theme) => ({
   layout: {
     margin: 0,
     padding: 0,
     minHeight: "100vh",
     position: "relative",
     paddingBottom: 234,
     boxSizing: "border-box",
   },
 }));
 
 const IEpage = (
   <div style={{ margin: "10vw", textAlign: "center", }}>
     <h1>Internet Explorerでの表示に対応しておりません。</h1>
     <p>Google Chrome, Mirosoft Edge, Firefoxからご覧ください。</p>
   </div>
 )
 
 const Layout = ({ children,location,breakpoints }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       logo: file(relativePath: { eq: "svgs/stan_logo.svg" }) {
         publicURL
       },
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
 
   const classes = useStyles();
   const [isIE, IsIE] = useState(false);
   useEffect(() => {
     const userAgent = window.navigator.userAgent.toLowerCase();
     if(userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1 ) {
     //  console.log("Internet Explorerでの表示に対応しておりません。\n  Google Chrome, Mirosoft Edge, Firefoxからご覧ください。 ");
       IsIE(true)
     }
   },[])
 
   return (
     <div className={classes.layout}>
       <div style={{textAlign: "center",}}>
          <div style={{display: "inline-block",width: "80px", margintop: 5, marginleft: 20, marginbotom: 5}}>
            <a href= {`https://www.stansystem.co.jp`} target="_blank" rel="noreferrer"  aria-label="スタンシステム"　style = {{textDecoration: "none"}}>  
              <img src={data.logo.publicURL} width="80px" height="22px" alt="stan_logo" />
            </a>
         </div>
       </div>
 
       <Header breakpoints = {breakpoints} siteTitle={data.site.siteMetadata?.title || `Title`} pathname={location?.pathname} />
       <main>
         { isIE ? IEpage : children }
       </main>
       <Footer breakpoints = {breakpoints}/>
     </div>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 